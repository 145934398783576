.back {
    padding: 0;
}

.account {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
    white-space: nowrap;

    h3 {
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
}

.tooltip {
    max-width: unset;

    :global(.ant-tooltip-inner) {
        word-wrap: initial;
    }
}
