.footer:global.ant-layout-footer {
    background: #3d4d6b;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :global .ant-row {
        margin-bottom: 0;
    }
}

.content {
    width: 100%;
    max-width: 1200px;

    :global .ant-col {
        text-align: center;
        margin: 10px 0;
    }
}

.logo {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 28px;
        color: #ffffff;
    }

    img {
        width: 80px;
        margin: 0 5px 0 0;
    }
}

.info {
    div:first-child {
        display: flex;
        justify-content: space-around;
    }

    div {
        font-size: 14px;
        color: #cbcbcbff;
    }

    a {
        font-size: 14px;
    }
}

.email {
    a,
    a:hover {
        color: #ffffff;
        font-size: 22px;
    }
}

.disclaimer {
    max-width: 850px;
    text-align: center;
    color: #cbcbcbff;
    font-size: 12px;
}

.icon {
    margin-right: 10px;

    svg {
        width: 17px;
        height: 17px;
    }
}

.rithmic {
    height: 40px;
    text-align: center;
}
