@import 'src/shared/styles/consts';

.wrapper {
    max-width: $g_screen_xl;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
}

.title {
    font-size: 34px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.video {
    min-height: 480px;
    max-width: $g_screen_md;
}

.description {
    margin: 35px 0;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: $g_screen_md) {
    .video {
        min-height: 350px;
    }
}

@media screen and (max-width: $g_screen_sm) {
    .title {
        font-size: 30px;
    }

    .video {
        min-height: 250px;
    }
}

@media screen and (max-width: $g_screen_xxs) {
    .video {
        min-height: 100px;
    }
}
