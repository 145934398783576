.title {
    display: flex;
    justify-content: space-between;
}

.importantText {
    color: red;
    font-weight: bold;
}

.card {
    min-height: 280px;
    margin: 10px 0;
    line-height: 1.3;
}

.stoppedInfo {
    margin-bottom: 10px;
}

.notification {
    font-size: 17px;
    line-height: 1.3;
}

@media screen and (max-width: 767px) {
    .card {
        min-height: auto;
        padding-bottom: 15px;
    }

    .notification {
        font-size: 12px;
        line-height: 1.3;
    }
}
