.wrapper {
    max-width: 1440px;
    margin-right: auto !important;
    margin-left: auto !important;
}

.text {
    margin: 7px 0;
    text-align: center;
}
