@import '~shared/styles/consts';

.guideBlock {
    margin-bottom: 200px;
}

.wrapper {
    margin-bottom: 40px;

    ol {
        font-size: large;
        margin-bottom: 40px;

        li,
        a {
            font-size: 16px;
        }
    }

    img {
        max-width: 100%;
        margin: 20px 0;
        border: 1px solid #bfbfbf;
    }
}

.header {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.title {
    font-size: 35px;
    margin-bottom: 20px;
}

.subTitle {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 25px;
    font-weight: bold;
}

.contents {
    font-size: 21px;
    margin: 15px;
    font-weight: bold;
}

.anchor {
    font-size: 15px;
    margin-bottom: 5px;
    white-space: break-spaces;
}

.note {
    font-size: 16px;
    color: orange;
}

.button {
    position: sticky;
    margin-top: 10px;
    margin-left: -10px;
    top: 30px;
}

.video {
    margin: 20px 0;
    min-height: 450px;
    width: 800px;
}

li img {
    display: block;
    margin-top: 10px;
    max-width: 100%;
}

@media screen and (max-width: $g_screen_md) {
    .header {
        font-size: 21px;
    }

    .title {
        font-size: 18px;
    }
}

@media screen and (max-width: $g_screen_xs) {
    .video {
        min-height: 100px;
    }
}
