.wrapper {
    display: flex;

    :global .ant-slider {
        width: 100%;
    }
}

.input {
    margin-left: 15px;
}
