.termsAndConditions {
    padding: 20px;
    line-height: 1.6;

    h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    h2 {
        font-size: 1.5em;
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    p {
        margin-bottom: 1em;
    }
}
