.leverage {
    .ant-input-number {
        margin-left: 25px;
        top: 5px;
        height: 40px;
        width: 90px;
        display: flex;
        align-items: center;

        input {
            font-size: 16px;
        }
    }
}
