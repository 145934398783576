@import 'src/shared/styles/consts';
@import 'src/shared/styles/utils';
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900&display=swap');

/* Define keyframes for the animated gradient */
@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Define keyframes for the fade-in animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.wrapper {
    background: linear-gradient(90deg, #059287, #071770, #b7263d);
    background-size: 200% 200%;
    animation: animatedGradient 25s ease infinite;
    margin: 0 -20px;
    padding: 100px 0;
    color: #ffffff;
}

.container {
    margin: auto !important;
    padding: 0 20px;
    max-width: $g_screen_xl;
}

.title {
    font-family: 'Urbanist', sans-serif;
    animation: fadeInUp 1s ease-in-out;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 59px;
    font-weight: bold;
    margin-bottom: 20px;
    opacity: 0;
    animation-fill-mode: forwards;
}

.text {
    font-size: 24px;
    animation: fadeInUp 1s ease-in-out 0.5s; /* Add a delay of 0.5s */
    margin-bottom: 20px;
    opacity: 0;
    animation-fill-mode: forwards;
}

.premium {
    text-align: center;
    margin: 30px auto;
    font-size: 30px;
    line-height: 1.3;
    color: #ffffff;
}

.button {
    text-align: center;
    width: 50%;
    font-size: 24px;
    margin-top: 30px;
}

.join {
    text-align: center;
}

.image {
    width: 360px;
}

.video {
    min-height: 315px;
    max-width: 100%;
    margin: auto;
    display: block;
}

.carouselWrapper {
    position: relative;
    perspective: 1000px;
    margin-left: 20px; /* Shift the carousel to the right */
}

.carousel {
    overflow: visible;
    transition: transform 0.3s ease; /* Smooth transition */
}

.carousel:hover {
    transform: translateY(-2px); /* Move 2px up on hover */
}

.carouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    will-change: transform;
}

.prevArrow,
.nextArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    font-size: 18px;
    color: white;
    padding: 8px;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: padding 0.3s ease, font-size 0.3s ease;
    user-select: none; /* Prevent text selection */
    pointer-events: all; /* Ensure pointer events are active */
}

.prevArrow {
    left: -30px;
}

.nextArrow {
    right: -30px;
}

.prevArrow:hover,
.nextArrow:hover {
    padding: 10px;
    font-size: 22px;
}

@media screen and (max-width: $g_screen_xl) {
    .image {
        width: 300px;
    }

    .video {
        min-height: 315px;
    }
}

@media screen and (max-width: $g_screen_lg) {
    .container {
        padding: 0 20px;
    }

    .title {
        margin-bottom: 20px;
    }

    .image {
        display: none;
    }

    .video {
        min-height: 400px;
    }
}

@media screen and (max-width: $g_screen_md) {
    .wrapper {
        padding: 50px 0;
    }

    .title {
        font-size: 42px;
    }

    .text {
        font-size: 21px;
    }

    .video {
        min-height: 300px;
    }
}

@media screen and (max-width: $g_screen_xs) {
    .title {
        font-size: 31px;
    }

    .text {
        font-size: 16px;
    }

    .video {
        min-height: 150px;
    }
}

.align-middle {
    display: flex;
    align-items: center;
}
