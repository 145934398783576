@import 'src/shared/styles/consts';

/* Define keyframes for the animated number color */
@keyframes animatedNumberColor {
    0% {
        color: #b7263d;
    }
    25% {
        color: #071770;
    }
    50% {
        color: #b7263d;
    }
    75% {
        color: #071770;
    }
    100% {
        color: #b7263d;
    }
}

.wrapper {
    max-width: $g_screen_xl;
    margin: 0 auto;
    padding: 100px 20px;
}

.title {
    text-align: center;
    font-weight: bold;
    font-size: 42px;
    margin-bottom: 40px;
}

.steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.step {
    margin: 0 auto;
    width: 350px;
    padding: 5px 10px;
}

.number {
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 20px;
    /* Apply animated number color */
    animation: animatedNumberColor 17s ease infinite;
}

.text {
    font-size: 20px;
    font-weight: 300;
}

@media screen and (max-width: $g_screen_sm) {
    .title {
        font-size: 30px;
    }
}
