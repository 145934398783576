.button {
    margin-bottom: 10px;
}

.balance {
    margin-bottom: 10px;

    div {
        font-size: 14px;
    }
}

.input :global(.ant-input-number-group-wrapper) {
    display: block;
}
