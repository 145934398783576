body {
    // Вспомогательные классы для нижнего внутреннего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .pb-#{$i * 5} {
            padding-bottom: #{$i * 5}px;
        }
    }

    // Вспомогательные классы для верхнего внутреннего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .pt-#{$i * 5} {
            padding-top: #{$i * 5}px;
        }
    }

    // Вспомогательные классы для верхнего внешнего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .mt-#{$i * 5} {
            margin-top: #{$i * 5}px;
        }
    }

    // Вспомогательные классы для нижнего внешнего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .mb-#{$i * 5} {
            margin-bottom: #{$i * 5}px;
        }
    }

    // Вспомогательные классы для правого внешнего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .mr-#{$i * 5} {
            margin-right: #{$i * 5}px;
        }
    }

    // Вспомогательные классы для левого внешнего отступа элемента, с шагом 5px, мин 5px, макс 150px
    @for $i from 0 through 30 {
        .ml-#{$i * 5} {
            margin-left: #{$i * 5}px;
        }
    }

    // Жирность - bold
    .b500 {
        font-weight: 500;
    }

    // Жирность - normal
    .b400 {
        font-weight: 400;
    }

    .d-block {
        display: block;
    }

    .d-iblock {
        display: inline-block;
    }

    .d-none {
        display: none;
    }

    .d-flex {
        display: flex;
    }

    .d-iflex {
        display: inline-flex;
    }

    .d-grid {
        display: grid;
    }

    .d-table {
        display: table;
    }

    .static {
        position: static;
    }

    .fixed {
        position: fixed;
    }

    .absolute {
        position: absolute;
    }

    .relative {
        position: relative;
    }

    .sticky {
        position: sticky;
    }

    .pointer {
        cursor: pointer;
    }

    .rotate90 {
        transform: rotate(90deg);
    }
}
