@import 'src/shared/styles/index';

.banner {
    margin: -20px -20px 0;
    position: relative;
    height: 300px;
}

.img {
    background-image: url(../../shared/img/bars.jpg);
    background-size: cover;
    height: 100%;
}

.overlay {
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
    background-color: #0d9dff;
    mix-blend-mode: hue;
}

.title {
    position: absolute;
    left: 0;
    top: 35%;
    right: 0;
    text-align: center;
    font-size: 60px;
    color: #ffffff;
    animation-name: fadeInUp;
    animation-duration: 1.25s;
}

.guide {
        margin-left: 5%;
    margin: 30px auto;
    padding: 30px;
    max-width: $g_screen_xl;

    &Title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 25px;
    }

    li,
    &Link {
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 10px;
        line-height: 1.3;
    }
}

.halfWidthContent {
    margin-right: 10%; /* Выравниваем по центру */
    margin-left: 1%;
}

.stepList {
    font-size: 18px; /* Делаем весь текст одного размера */
    line-height: 1.8; /* Увеличиваем расстояние между строками */
}

.stepList li {
    margin-bottom: 24px; /* Делаем большие отступы между шагами */
}

.fullText {
    display: block; /* Чтобы текст ссылок был таким же, как основной текст */
    font-size: 18px; /* Делаем один размер шрифта для всех строк */
}

.subTitle {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    margin: 60px 0;
}

.exchanges {
    margin: 30px auto;
    padding: 30px;
    max-width: $g_screen_xl;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.link {
    margin: 0 auto;
    transition: scale 300ms;

    img {
        width: 150px;
        height: 90px;
        margin: 0 10px;
    }

    &:hover {
        scale: 1.02;
        border-radius: 10px;
        box-shadow: #a4a4a419 0 0 50px 10px;
    }
}

.guidesButton {
    text-align: center;
}

.toGuides {
    margin: 5px 0 20px;
    padding-top: 12px;
    height: 55px;

    span {
        font-size: 18px;
    }
}

.exchange {
    margin: 0 -20px;
    border-radius: 0;

    &Title {
        font-size: 25px;
        margin: 10px auto 30px;
        text-align: center;
        white-space: break-spaces;
        max-width: $g_screen_xl;
    }

    &Content {
        margin: auto !important;
        max-width: $g_screen_xl;
    }

    li {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}

.broker {
    margin: 0 auto;
    width: 250px;
    text-align: center;

    img {
        width: 85%;
        height: auto;
    }
}

.text {
    text-align: center;
    font-size: 20px;
    margin: 10px 0;
}

.mail {
    margin: 100px 0 80px;
    font-weight: 600;
    text-align: center;

    a {
        color: var(--g_main_text);
    }

    div {
        font-size: 30px;
    }
}

@media screen and (max-width: $g_screen_sm) {
    .banner {
        height: 150px;
    }

    .title {
        font-size: 35px;
    }
}
 