@import 'src/shared/styles/consts';

.wrapper {
    max-width: $g_screen_xl;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 50px 50px;
}

.title {
    text-align: center;
    font-size: 42px;
    margin-bottom: 40px;
    margin-top: 20px;
    font-weight: bold;
    transform: rotate(0deg); /* Ensure the text is upright */
    white-space: normal; /* Ensure text wraps within the container */
}

.description {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5; /* Improve text readability */
    white-space: normal; /* Ensure text wraps within the container */
}

.total {
    margin: 0 -10px 30px;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    text-align: center; /* Center the text */

    div,
    span {
        font-size: 16px;
    }

    span {
        white-space: nowrap;
    }
}

.buttons {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 10px;
}

.button {
    text-align: center;
    height: 55px;
    align-content: center;
    display: inline-block;
    margin: 20px 10px 0 0;

    span {
        font-size: 18px;
    }
}

@media screen and (max-width: $g_screen_sm) {
    .title {
        font-size: 30px;
        text-align: center; /* Reaffirm text alignment */
    }

    .description {
        text-align: center;
        padding-left: 5%; /* Adjust padding for smaller screens */
        padding-right: 5%;
        line-height: 1.5; /* Maintain readability on smaller screens */
    }

    .total {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; /* Center the text */
        gap: 10px; /* Adjust gap between text lines if needed */
        margin: 0 auto; /* Center the total section within the parent */
    }

    .row {
        font-size: 12px;
    }
}
