@import 'src/shared/styles/consts';

* {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Exo', sans-serif;
}

.context {
    width: 100%;
    position: absolute;
    top: 50vh;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
    /* Apply animated text color */
    animation: animatedTextColor 20s ease infinite;
}

.area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

/* Define keyframes for the animated text color */
@keyframes animatedTextColor {
    0% {
        color: #059287;
    }
    25% {
        color: #071770;
    }
    50% {
        color: #b7263d;
    }
    75% {
        color: #071770;
    }
    100% {
        color: #059287;
    }
}

/* Define keyframes for the animated button background */
@keyframes animatedButtonBackground {
    0% {
        background-color: #059287;
    }
    25% {
        background-color: #071770;
    }
    50% {
        background-color: #b7263d;
    }
    75% {
        background-color: #071770;
    }
    100% {
        background-color: #059287;
    }
}

/* Define keyframes for the animated icon color */
@keyframes animatedIconColor {
    0% {
        color: #071770;
    }
    25% {
        color: #059287;
    }
    50% {
        color: #071770;
    }
    75% {
        color: #059287;
    }
    100% {
        color: #071770;
    }
}

.wrapper {
    text-align: center;
    margin: 90px auto;
    max-width: $g_screen_xl;
}

.title {
    font-weight: bold;
    font-size: 42px;
    /* Apply animated text color */
    animation: animatedTextColor 20s ease infinite;
}

.description {
    margin: 50px 0;
    font-size: 22px;
    font-weight: 300;
    color: #000000; /* Set description text color to black */
}

.icon {
    display: block;
    margin: auto;
    font-size: 50px;
    /* Apply animated icon color */
    animation: animatedIconColor 20s ease infinite;
}

.text {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.5;
}

.button {
    height: 55px;
    padding-left: 45px;
    padding-right: 45px;
    /* Apply animated button background */
    animation: animatedButtonBackground 25s ease infinite;

    span {
        margin-top: 10px;
        font-size: 18px;
    }
}

@media screen and (max-width: $g_screen_md) {
    .icon {
        font-size: 40px;
    }
}

@media screen and (max-width: $g_screen_sm) {
    .wrapper {
        margin: 50px auto;
    }

    .title {
        font-size: 30px;
    }

    .button {
        height: 35px;
        padding-left: 15px;
        padding-right: 15px;

        span {
            margin-top: 3px;
            font-size: 12px;
        }
    }
}
