// Media queries breakpoints
// Extra small screen / phone
$g_screen_xxs: 320px;
$g_screen_xs: 480px;

// Small screen / tablet
$g_screen_sm: 576px;

// Medium screen / desktop
$g_screen_md: 768px;

// Large screen / wide desktop
$g_screen_lg: 992px;

// Extra large screen / full hd
$g_screen_xl: 1200px;

// Extra extra large screen / large desktop
$g_screen_xxl: 1600px;

$header_height: 65px;

$main_font_family: OpenSans;
$fonts: $main_font_family, sans-serif;
