.wrapper {
    text-align: center;
    //max-width: 304px;
    margin: 0px 10px 10px 10px;
}

.row {
    border: 1px solid #ededed;
    border-radius: 15px;
    padding: 20px;
}

.img {
    width: 80px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.sub_title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}

.terms {
    margin: 10px 0 15px;

    :global .ant-checkbox {
        top: 10px;
    }

    &Error :global .ant-checkbox {
        .ant-checkbox-inner {
            border-color: red;
        }
    }
}

.captcha {
    border: 1px solid red;
    display: inline-block;
}

.button {
    margin: 10px 0;

    span {
        font-size: 14px;
    }
}
