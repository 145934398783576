@import 'core/fonts/stylesheet';

@import 'shared/styles/consts';
@import 'shared/styles/theme';

@import 'core/styles/redifinition';
@import 'core/styles/typography';
@import 'core/styles/form';
@import 'core/styles/scroll';
@import 'core/styles/helpers';

body {
    overflow-y: scroll;
}

body,
.ant-layout {
    background: var(--g_main_background);
}
