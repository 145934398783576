.wrapper {
    display: flex;
    align-items: center;
}

.refresh {
    width: 25px;

    svg {
        font-size: 14px;
    }
}
