@import 'src/shared/styles/consts';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 20px;

    @media screen and (max-width: $g_screen_sm) {
        text-align: center;
        display: block;
        padding: 0;

        h1 {
            margin: 10px;
        }
    }
}

.tags span {
    margin-right: 0;
}

.container {
    height: 500px;
}
