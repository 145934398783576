.wrapper {
    text-align: center;
    max-width: 304px;
    margin: 0 auto;
}

.img {
    width: 80px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.captcha {
    border: 1px solid red;
}

.button {
    margin: 10px 0;

    span {
        font-size: 14px;
    }
}
