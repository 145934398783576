@import '~shared/styles/consts';

@mixin tagStyle() {
    color: var(--g_main_text);
    border-color: #1890ff;
}

.wrapper {
    margin-bottom: 10px;

    :global .ant-checkbox-wrapper {
        margin-top: 7px;

        span {
            padding-right: 0;
        }
    }
}

.tags {
    margin: 5px 0;
    text-align: right;

    :global .ant-tag-checkable-checked {
        background-color: transparent;
        @include tagStyle();
    }

    :global .ant-tag-checkable:active {
        @include tagStyle();
    }
}

.buttons {
    display: flex;
    margin-top: 15px;

    button {
        width: 100%;
        border-radius: 0;
    }
}
