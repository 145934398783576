@import '~shared/styles/consts';

.blockGet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
}

.input {
    width: 100%;
    margin-bottom: 0;

    :global .ant-input-number-group-wrapper {
        display: inline;
    }

    :global .ant-input-number-prefix {
        font-size: 16px;
    }

    :global .ant-input-number-group-addon {
        width: 110px;
        padding: 0;

        .anticon + span {
            font-size: 16px;
        }

        svg {
            font-size: 15px;
            margin-right: 5px;
        }
    }

    :global .ant-input-number-disabled {
        border: none;
        color: var(--g_main_text);
        background: transparent;

        input {
            cursor: text;
        }
    }
}

.label {
    font-size: 16px;
    margin-left: 10px;
    min-width: 70px;
    text-align: right;
}

.exchangeCoin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
        font-size: 16px;
        padding-left: 11px;
    }
}

.register span {
    font-size: 16px;
}

.coin {
    width: 110px;
    padding-left: 20px;
}

.icon {
    & + span {
        font-size: 16px;
    }

    svg {
        font-size: 15px;
        margin-right: 5px;
    }
}

.resultMessage div {
    font-size: 15px;
}
