.wrapper {
    h2 {
        display: inline;
    }

    :global .ant-statistic {
        margin: 10px 0;

        .ant-statistic-title {
            margin-bottom: 0;
        }

        span {
            font-size: 16px;
        }
    }
}

.stat {
    :global .ant-statistic-title {
        white-space: nowrap;
    }
}

.tip svg {
    font-size: 11px;
    position: relative;
    top: -7px;
    right: -4px;
}
