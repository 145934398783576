@import 'src/shared/styles/consts';

.ant-row {
    margin-bottom: 0 !important;
}

.ant-collapse {
    font-family: $fonts;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-btn {
    border-radius: 5px;
}
