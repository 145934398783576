@import 'src/shared/styles/consts';

@keyframes animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.wrapper {
    /* Use linear gradient as the initial background */
    background: linear-gradient(90deg, #059287, #071770, #b7263d);
    /* Set background size to cover the entire element */
    background-size: 200% 200%;
    /* Animate the gradient with defined keyframes */
    animation: animatedGradient 25s ease infinite;
    margin: 0 -20px;
    padding: 100px 0;
    color: #ffffff;
    text-align: center;
}

.container {
    margin: auto;
    max-width: $g_screen_xl;
}

.header {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 60px;
    color: #ffffff;
}

.feature {
    color: #ffffff;
    margin-bottom: 30px;
}

.icon {
    font-size: 50px;
    margin-bottom: 20px;
}

.title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.text {
    margin: auto;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    max-width: 300px;
}

@media screen and (max-width: $g_screen_sm) {
    .header {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .title {
        font-size: 18px;
    }

    .text {
        font-size: 12px;
    }
}
