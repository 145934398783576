@import 'src/shared/styles/consts';

/* Define keyframes for the animated collapse text color */
@keyframes animatedCollapseColor {
    0% {
        color: #fff; /* Initial color */
    }
    25% {
        color: #071770; /* Intermediate color */
    }
    50% {
        color: #b7263d; /* Intermediate color */
    }
    75% {
        color: #071770; /* Intermediate color */
    }
    100% {
        color: #fff; /* Final color */
    }
}

/* Apply color animation to all collapse headers */
.collapse {
    :global {
        .ant-collapse-header {
            /* Apply animated collapse text color */
            animation: animatedCollapseColor 15s ease infinite;
            color: #000000; /* Title color set to black */
        }

        .ant-collapse-content p {
            /* Apply animated collapse text color */
            animation: animatedCollapseColor 15s ease infinite;
        }

        .ant-collapse-item {
            border-bottom: none;
            padding: 10px 25px 10px 25px;
            margin: 20px 0;
            border-radius: 30px !important;

            .ant-collapse-header-text {
                font-size: 30px;
                animation: animatedCollapseColor 15s ease infinite;
                color: #fff; /* Ensure title text color is white */
            }

            .ant-collapse-content {
                p {
                    font-size: 18px;
                    margin-bottom: 20px;
                    color: #fff;

                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

/* Apply gradient background to collapse */
.ant-collapse {
    background: linear-gradient(90deg, #24a0ff 0%, #007bd8 100%);
}

/* Styles for inner collapse panels */
.innerCollapse {
    background: #00b3ff; /* Same color as staking */
    border-radius: 10px;

    .innerPanel {
        .ant-collapse-header {
            font-size: 18px;
            background-color: #00b3ff;
            font-weight: bold;
            animation: animatedCollapseColor 15s ease infinite;
            color: #fff; /* Ensure inner panel title text color is white */
        }

        .ant-collapse-content {
            p {
                font-size: 16px;
                color: #006eff; /* Ensure inner panel content text color is blue */
            }
        }
    }
}

/* Other styles remain unchanged */
.container {
    margin: 30px auto;
    padding: 20px 80px 50px 80px;
    max-width: $g_screen_xl;
    color: #ffffff;
}

.title {
    text-align: center;
    font-size: 42px;
    margin-bottom: 50px;
    margin-top: 30px;
    color: #000000; /* Set title color to black */
}

.regbutton {
    display: inline-flex;
    align-items: center;
    padding: 30px 40px;
    border-radius: 30px;

    span {
        font-size: 20px;
        color: #000000;
    }
}

.anchor {
    :global .ant-anchor-ink::before {
        display: none;
    }

    button,
    .binance {
        border-radius: 15px;
        border: none;

        span {
            color: #1db1f0;
        }
    }

    .binance {
        display: inline-flex;
        align-items: center;
        padding: 4px 15px;
        margin-left: 16px;

        svg {
            margin-right: 3px;
            width: 12px;
            height: 12px;
        }
    }
}

.call {
    text-align: center;
    font-size: 26px;
    margin-bottom: 35px;
    margin-top: 30px;
    color: #fff;
}

.list {
    color: #fff;
    font-size: 18px;
}

.trade {
    background-color: #1db1f0;
}

.coin {
    background-color: #008dc9;

    a {
        border-radius: 15px;
        border: none;

        span {
            position: relative;
            top: 1px;
            color: #008dc9;
        }
    }
}

.ref {
    background-color: #4ab9e9;

    a {
        border-radius: 15px;
        border: none;

        span {
            position: relative;
            top: 1px;
            color: #4ab9e9;
        }
    }
}

.stak {
    background-color: #036b98;

    a {
        border-radius: 15px;
        border: none;

        span {
            position: relative;
            top: 1px;
            color: #036b98;
        }
    }
}

.faqheader {
    text-align: center;
    font-size: 42px;
    margin-bottom: 20px;
    color: #000;
}

@media screen and (max-width: $g_screen_md) {
    .container {
        padding: 20px 20px;
    }
}
