@import 'src/shared/styles/consts';

.wrapper {
    button {
        height: 55px;
        padding: 0 40px;

        span {
            font-size: 18px;
        }
    }
}

.tronLink {
    height: 55px;

    span {
        font-size: 18px;
        margin: 8px 0;
    }
}

.noPrimary span {
    color: #0095ff;
}

@media screen and (max-width: $g_screen_xs) {
    .tronLink {
        height: auto;

        span {
            font-size: 12px;
            margin: 0;
        }
    }
}
