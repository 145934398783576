@import 'src/shared/styles/consts';

.wrapper {
    height: 100%;
}

.tronLink {
    span {
        font-size: 14px;
        color: #ffffff;
    }
}

@media screen and (max-width: $g_screen_xs) {
    .tronLink {
        padding-left: 0;
        padding-right: 0;

        span {
            font-size: 12px;
        }
    }
}
