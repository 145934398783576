@import 'src/shared/styles/consts'; // Ensure this path is correct

.wrapper:global.ant-layout-header {
    height: $header_height;
    padding: 0 30px;
    box-shadow: 0 0 3px gray;
    background: #ffffff;
    display: flex;
    align-content: center;
    position: relative; /* Ensure relative positioning for absolute children */
}

.languageSelector {
    z-index: 0; /* Ensure it appears correctly in the stacking context */
}

.menuButton {
    position: absolute;
    top: 13px;
    right: 15px;
    z-index: 10;
}

.logo {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        margin: 0 2px 0 10px;
    }
}

.desktopSubMenu {
    :global .ant-menu-vertical .ant-menu-submenu-selected {
        color: var(--g_main_text);
    }

    :global .ant-menu-title-content {
        &,
        a {
            font-size: 14px;
        }
    }
}

.desktopMenu {
    background: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    :global .ant-menu-submenu-title {
        &:active {
            background: transparent;
        }

        &:focus-visible {
            outline: none;
        }
    }

    :global .ant-menu-title-content {
        &,
        & a:not(.ant-btn) {
            font-size: 18px;
        }
    }
}

.desktop {
    display: flex;
    align-items: center;
}

.drawer {
    :global .ant-drawer-content-wrapper {
        min-width: 200px;
        max-width: 350px;
        width: 60% !important;

        .ant-drawer-body {
            text-align: center;
            padding-top: 0;

            button {
                width: 80%;
                margin-bottom: 10px;
            }
        }
    }
}

.logout {
    position: relative;
    top: 14px;
    margin-left: 15px;
}

.real {
    color: #00537e;
    font-size: 28px;
    font-weight: 600;
}

.colibri {
    color: #00aadb;
    font-size: 28px;
    font-weight: 600;
}

.itemLogo {
    width: 15px;
    height: 15px;
}

@media screen and (max-width: $g_screen_sm) {
    .wrapper {
        padding: 0 5px;
    }
    .languageSelector {
        position: relative; /* Reset to relative in mobile view */
        top: auto;
        right: auto;
        z-index: 5; /* Ensure it appears in the correct order */
    }
}
