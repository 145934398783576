.wrapper {
    width: 100%;
    max-width: 800px;
    padding: 5px 0 20px;
    color: #fff;
}

.rights {
    text-align: center;
    margin-bottom: 10px;
}
