@import 'src/shared/styles/consts';

.banner {
    margin: -20px -20px 0;
    position: relative;
    height: 300px;
}

.banner_img {
    background-image: url(../../shared/img/premium.png);
    background-size: cover;
    height: 100%;
}

.overlay {
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
    mix-blend-mode: hue;
}

.title {
    position: absolute;
    left: 0;
    top: 35%;
    right: 0;
    text-align: center;
    font-size: 60px;
    color: #ffffff;
    animation-name: fadeInUp;
    animation-duration: 1.25s;
}

.table {
    padding: 20px;
    margin: 50px 20% 50px 20%;
    overflow: hidden;
}

.guide {
    margin: 30px auto;
    padding: 50px;
    text-align: center;
    max-width: $g_screen_md;

    &button {
        font-size: 24px;
        margin-top: 25px;
        width: 35%;
    }

    &Title {
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 25px;
    }

    &H1 {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
    }

    &p {
        margin-bottom: 20px;
        text-align: left;
        font-size: 17px;
        line-height: 1.3;
    }
}

.subTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin: 30px 0;
}

.header {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    margin: 50px 0;
    line-height: 1.3;
}

.text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
    line-height: 1.3;
}

.textlist {
    text-align: left;
    margin-top: 30px;

    li {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
    }
}

.card {
    margin: 30px 0 15px 0;

    p {
        font-size: 20px;
    }

    ol {
        text-align: left;
        margin-top: 0;
    }

    ul {
        text-align: left;
        margin-top: 0;
    }

    li {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
    }
}

.desc {
    text-align: left;
    margin-right: 20%;
    margin-left: 20%;

    p {
        font-size: 20px;
    }
}

.link {
    margin: 0 auto;
    transition: scale 300ms;

    img {
        width: 150px;
        height: 90px;
        margin: 0 10px;
    }

    &:hover {
        scale: 1.02;
        border-radius: 10px;
        box-shadow: #a4a4a419 0 0 50px 10px;
    }
}

.toGuides {
    margin: 5px 0 20px;
    padding-top: 12px;
    height: 75px;

    span {
        font-size: 18px;
    }
}

.mail {
    margin: 100px 0 80px;
    font-weight: 600;
    text-align: center;

    a {
        color: var(--g_main_text);
    }

    div {
        font-size: 20px;
    }
}

.wrapper {
    margin: 0 -20px;
    padding: 50px 0;
}

.container {
    margin: auto !important;
    padding: 0 20px;
    max-width: $g_screen_xl;
}

.premium {
    text-align: center;
    margin: 30px auto;
    font-size: 30px;
    line-height: 1.3;
    color: #ffffff;
}

.button {
    text-align: center;
    width: 50%;
    font-size: 24px;
    margin-top: 30px;
}

.join {
    text-align: center;
}

.image {
    width: 360px;
}

.video {
    min-height: 315px;
    max-width: $g_screen_md;
    margin-top: 25px;
}

@media screen and (max-width: $g_screen_xl) {
    .image {
        width: 300px;
    }

    .video {
        min-height: 315px;
    }
}

@media screen and (max-width: $g_screen_lg) {
    .container {
        padding: 0 20px;
    }

    .title {
        margin-bottom: 40px;
    }

    .image {
        display: none;
    }

    .video {
        min-height: 400px;
    }
}

@media screen and (max-width: $g_screen_md) {
    .wrapper {
        padding: 50px 0;
    }

    .title {
        font-size: 42px;
    }

    .text {
        font-size: 21px;
    }

    .video {
        min-height: 300px;
    }
}

@media screen and (max-width: $g_screen_sm) {
    .banner {
        height: 150px;
    }

    .title {
        font-size: 35px;
    }
}

@media screen and (max-width: $g_screen_xs) {
    .title {
        font-size: 31px;
    }

    .text {
        font-size: 16px;
    }

    .video {
        min-height: 150px;
    }
}
