@import 'src/shared/styles/consts';

.wrapper {
    text-align: center;
    padding: 25px 20px 75px 20px;
    margin: 0 -20px;
}

.title {
    font-weight: bold;
    font-size: 42px;
    margin-bottom: 50px;
}

@media screen and (max-width: $g_screen_md) {
    .title {
        font-size: 30px;
    }
}
