@import '~shared/styles/consts';

.wrapper {
    margin: 10px 0;

    svg {
        font-size: 16px;
    }

    div {
        font-size: 16px;
        font-weight: bold;
    }

    @media screen and (max-width: $g_screen_sm) {
        svg {
            font-size: 21px;
        }

        div {
            font-size: 21px;
        }
    }
}

.balance {
    display: flex;
    justify-content: center;
}
