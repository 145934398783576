@import 'src/shared/styles/consts';

.wrapper {
    color: #ffffff;
    text-align: center;
    margin: 30px auto 5px auto;
    max-width: $g_screen_xl;

    .wrapper:target {
        padding-top: 50px;
    }
}

.bwrapper {
    background-color: #ffffff;
    padding: 30px 20px;
}

.title {
    font-size: 42px;
    font-weight: bold;
}

.subtitle {
    color: #000000;
    margin: auto;
    font-size: 24px;
    //max-width: 300px;
}

.call {
    text-align: center;
    font-size: 26px;
    //font-weight: 300;
    //margin-bottom: 25px;
    margin-top: 30px;

    p,
    li {
        font-size: 20px;
        line-height: 1.3;
    }

    span {
        font-size: 20px;
        line-height: 1.3;
        font-weight: bold;
    }
}

.partners {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.link {
    margin: 0 auto;
    transition: scale 300ms;

    img {
        width: 100px;
        height: 60px;
        margin: 0 10px;
    }

    &:hover {
        scale: 1.02;
        border-radius: 10px;
        box-shadow: #a4a4a419 0 0 50px 10px;
    }
}

.button {
    padding: 10px 20px;
    height: 50px;

    span {
        font-size: 18px;
    }
}

@media screen and (max-width: $g_screen_md) {
    .link img {
        width: 75px;
        height: 45px;
    }
}

@media screen and (max-width: $g_screen_xs) {
    .button {
        padding: 7px 10px;
        height: 35px;

        span {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: $g_screen_sm) {
    .wrapper {
        margin: 10px auto;
    }

    .title {
        font-size: 30px;
    }
}
