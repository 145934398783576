.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text {
    margin: 5px 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label {
    display: inline-block;
    font-size: 16px;
    width: 90px;
}

.key {
    font-size: 16px;
}
