@import '../../../../../shared/styles/consts';

@mixin tagStyle() {
    color: var(--g_main_text);
    border-color: #1890ff;
}

.wrapper {
    margin: -5px 0 10px 10px;

    :global .ant-tag-checkable {
        border: 1px solid #e0e0e0;

        &-checked {
            background-color: transparent;
        }
    }

    :global .ant-tag-checkable:active {
        @include tagStyle();
    }

    :global .ant-tag-checkable-checked {
        @include tagStyle();
    }
}

.tooltip {
    color: white;

    > * {
        font-size: 11px;
        margin: 0 3px;
        color: white;
    }
}
